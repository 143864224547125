import React, {Component} from 'react';
import Fade from 'react-reveal';
import ButtonArray from "../shared/ButtonArray";
import NavigationElement from "../shared/NavigationElement";
import Instruction from "../shared/Instruction";
import Cookies from "react-cookie/es6/Cookies";


const steps = [
    {
        key: 'tankpit',
        active: true,
        title: 'Tank Pit',
    },
    {
        key: 'tank',
        disabled: true,
        title: 'Tank',
    },
    {key: 'item', disabled: true, title: 'Item'},
];


class TankPits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
		}
	};

	componentDidMount() {
		let self = this;
		const cookies = new Cookies();
		fetch(`${process.env.REACT_APP_API_BASE_URL}/container/all`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Auth-Token': cookies.get('authToken')
				}
			}).then(function (response) {
			if (!response.ok) {
				console.log("error", response);
				// window.location.replace("/login");
				throw new Error(response.statusText);
			}
			return response.json()
		}).then(function (data) {
			let i;
			for (i = 0; i < data.containerIds.length; i++) {
				data.containerIds[i].name = data.containerIds[i]['id'];
				data.containerIds[i].url = "/steel/tankpits/" + data.containerIds[i]['id'] + "/tanks/all";

				if (data.containerIds[i].completionStatus.completed === data.containerIds[i].completionStatus.total) {
					data.containerIds[i].color = "#20AA01";
				} else {
					data.containerIds[i].color = "#4E4E50";
				}
			}
			self.setState({items: data.containerIds});
		}).catch(error => console.log(error));
	}
     render() {
        return (
            <div>
                <Fade>
                    <NavigationElement items={steps}/>
	                <Instruction text="Select a tank pit"/>
	                <ButtonArray prefix="Tank Pit " items={this.state.items}/>
                </Fade>
            </div>
        );
    }
}

export default TankPits;
