import React, {Component} from 'react';
import Fade from 'react-reveal';
import NavigationElement from "../shared/NavigationElement";
import Instruction from "../shared/Instruction";
import FullWidthButtonArray from "../shared/FullWidthButtonArray";

const buttonArray = [
	{name: "Additive Pump", url: "", counter: ""},
	{name: "Additive Vessel", url: "", counter: ""},
	{name: "Agitator", url: "", counter: ""},
	{name: "Blower", url: "", counter: ""},
	{name: "Capstan", url: "", counter: ""},
	{name: "Capstan", url: "", counter: ""},
	{name: "Degasser", url: "", counter: ""},
	{name: "Diesel Engine", url: "", counter: ""},
	{name: "Diesel Storage Tank", url: "", counter: ""},
	{name: "Diesel Storage Tank Pump", url: "", counter: ""},
];

const steps = [
	{
		key: 'tankpit',
		active: true,
		title: 'Type',
	},
	{
		key: 'tank',
		disabled: true,
		title: 'Location',
	},
	{key: 'item', disabled: true, title: 'Tag No'},
];


class SneakPreview extends Component {
	render() {
		return (
			<div>
				<Fade>
					<NavigationElement items={steps}/>
					<Instruction text="Select a type"/>
					<FullWidthButtonArray prefix="" items={buttonArray}/>
				</Fade>
			</div>
		);
	}
}

export default SneakPreview;