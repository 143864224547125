import React, {Component} from 'react';
import Fade from 'react-reveal';
import ButtonArray from "../shared/ButtonArray";
import NavigationElement from "../shared/NavigationElement";
import Instruction from "../shared/Instruction";
import Cookies from "react-cookie/es6/Cookies";

class Tanks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            steps: [
                {
                    key: 'tankpit',
                    title: 'Tank Pit ' + props.tankPitId,
                    href: "/steel/tankpits/all"
                },
                {
                    key: 'tank',
                    active: true,
                    title: 'Tank',
                },
                {key: 'item', disabled: true, title: 'Item'},
            ],
        };
    }

    componentDidMount() {
        let self = this;
        const cookies = new Cookies();
        fetch(`${process.env.REACT_APP_API_BASE_URL}/container/${this.props.tankPitId}/crates`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': cookies.get('authToken')
                }
            }).then(function (response) {
                if (!response.ok) {
                    console.log("error", response);
                    // window.location.replace("/login");
                    throw new Error(response.statusText);
                }
                return response.json()
            }).then(function (data) {
                let i;
                for (i = 0; i < data.crates.length; i++) {
	                data.crates[i].name = data.crates[i]['crateNumber'];
                    data.crates[i].url = "/steel/tankpits/" + self.props.tankPitId + "/tanks/" + data.crates[i]['crateNumber'] + "/items/all";

	                if (data.crates[i].completionStatus.completed === data.crates[i].completionStatus.total) {
		                data.crates[i].color = "#20AA01";
	                } else {
		                data.crates[i].color = "#4E4E50";
	                }
                }
                self.setState({items: data.crates});
            }).catch(error => console.log(error));
    }

    render() {
        return (
            <div>
                <Fade>
                    <NavigationElement items={this.state.steps}/>
	                <Instruction text="Select a tank" previousLink="steel/tankpits/all"/>
                    <ButtonArray prefix="Tank " items={this.state.items}/>
                </Fade>
            </div>
        );
    }
}

export default Tanks;
