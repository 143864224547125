import React, {Component} from 'react';
import Fade from 'react-reveal';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import Image from "semantic-ui-react/dist/commonjs/elements/Image/Image";

class Filler extends Component {
	render() {
		let rows = [];

		for (let i = 0; i < 6; i++) {
			rows.push(<Segment style={{margin: "1em 1em 1em 1em"}}>
				<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
			</Segment>)
		}

		return (
			<div>
				<Fade>
					{rows}
				</Fade>
			</div>
		);
	}
}

export default Filler;