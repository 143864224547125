import React, {Component} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Cookies from "react-cookie/es6/Cookies";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";

const buttonArrayStyle = {
	maxWidth: "800px",
	margin: "auto",
	paddingTop: "1.2em",
	padding: "2em, 1em",
	backgroundColor: "white"
};

const tableStyle = {
	width: "98%",
	margin: "1%",
	fontSize: "15px",
	backgroundColor: "#016A9B"
};

class ItemElementArray extends Component {
	constructor(props) {
		super(props);
		console.log(props);

		this.state = {
			items: props.items,
		}
	}

	componentWillReceiveProps(nextProps, nextContent) {
		this.setState({items: nextProps.items})
	}


	refreshItems() {
		let self = this;
		console.log("refreshed");
		const cookies = new Cookies();
		// fetch(process.env.REACT_APP_API_BASE_URL + "/hes-tracker/tank/" + this.props.tankId + "/items", {
		fetch(`${process.env.REACT_APP_API_BASE_URL}/crate/${this.props.tankId}/items`, {
			headers: {
				'Content-Type': 'application/json',
				'X-Auth-Token': cookies.get('authToken')
			}
		}).then(function (response) {
			if (!response.ok) {
				console.log("error", response);
				// window.location.replace("/login");
				throw new Error(response.statusText);
			}
			return response.json()
		}).then(function (tankItemsObj) {
			let tankItemsArray = tankItemsObj.crateItems;
			let matchedItem = tankItemsArray.find(x => x.itemName === self.props.itemName);
			let elementItems = matchedItem.items;
			self.setState({items: elementItems});
			console.log(elementItems)
		});
	}

	handleCheckIn = (e, id) => {
		const cookies = new Cookies();
		console.log(this.state);
		console.log(e, id);
		let self = this;
		// fetch(process.env.REACT_APP_API_BASE_URL + '/hes-tracker/item/' + id, {
		fetch(`${process.env.REACT_APP_API_BASE_URL}/item/${id}`, {
			method: 'PUT',
			headers: {'Content-Type': 'application/json', 'X-Auth-Token': cookies.get('authToken')},
			body: JSON.stringify({itemId: id.toString(), deliveryStatus: e.toString()})
		}).then(() => {
			self.refreshItems();
			self.close();
		}).catch(error => console.log(error));
	};

	state = {open: false, itemId: '', status: '', message: ''};

	show = (status, itemId, message) => () => this.setState({
		status: status,
		itemId: itemId,
		message: message,
		open: true
	});
	close = () => this.setState({open: false});

	renderButton(item) {
		if (item.deliveryStatus === "WAITING_FOR_APPROVAL") {
			return (
				<Icon color='orange'
				      name='hourglass'
				      size='large'
				/>
			);
		} else if (item.deliveryStatus === "BEING_INSPECTED") {
			return (
				<Icon color='orange'
				      name='magnify'
				      size='large'
				/>
			);
		} else if (item.deliveryStatus === "DELIVERED_AT_HHTT_SITE" || item.deliveryStatus === "WORKSHOP_TO_HHTT_SITE") {
			return (
				<Icon color='green'
				      name='checkmark'
				      size='large'
				      Button
				      onClick={this.show("SUPPLIER_TO_WORKSHOP",
					      item.id,
					      "Are you sure you want to cancel check in for this item?"
				      )}/>
			)
		} else {
			return (
				<Button onClick={this.show("WORKSHOP_TO_HHTT_SITE",
					item.id,
					"Are you sure you want to check in this item?")}>
					Check-in
				</Button>
			)

		}
	}

	render() {
		const {open} = this.state;


		return (
			<div style={buttonArrayStyle}>
				{this.state.items.map((item, index) => {
					return <Table style={tableStyle} key={index} color="#006A9B" celled unstackable
					              selectable inverted>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Type</Table.Cell>
								<Table.Cell>{item.plateType}</Table.Cell>
								<Table.Cell>Planned date</Table.Cell>
								<Table.Cell>{item.plannedDate}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>ID</Table.Cell>
								<Table.Cell colSpan="3">{item.id}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>Number</Table.Cell>
								<Table.Cell>{item.number}</Table.Cell>
								<Table.Cell>Weight</Table.Cell>
								<Table.Cell>{item.weight}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>Width</Table.Cell>
								<Table.Cell>{item.width}</Table.Cell>
								<Table.Cell>Length</Table.Cell>
								<Table.Cell>{item.length}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>Thickness</Table.Cell>
								<Table.Cell>{item.thickness}</Table.Cell>
								<Table.Cell>Border</Table.Cell>
								<Table.Cell>{item.border}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Finish</Table.Cell>
								<Table.Cell>{item.finish}</Table.Cell>
								<Table.Cell>Material</Table.Cell>
								<Table.Cell>{item.material}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell colSpan="2">Arrived at the workshop </Table.Cell>
								<Table.Cell colSpan="2">
									{item.deliveryStatus === "BEING_INSPECTED" || item.deliveryStatus === "SUPPLIER_TO_WORKSHOP" || item.deliveryStatus === "WORKSHOP_TO_HHTT_SITE" || item.deliveryStatus === "DELIVERED_AT_HHTT_SITE" || item.deliveryStatus === "WAITING_FOR_APPROVAL" || item.deliveryStatus === "WAITING_FOR_INSPECTION" ?
										<Icon color='green'
										      name='checkmark'
										      size='large'
										      Button
										      onClick={this.show("NONE",
											      item.id,
											      "Are you sure you want to cancel check in for this item?"
										      )}/>
										:
										<Button onClick={this.show("SUPPLIER_TO_WORKSHOP",
											item.id,
											"Are you sure you want to check in this item?")}>
											Check-in
										</Button>
									}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan="2">Finished at the workshop and ready for transport</Table.Cell>
								<Table.Cell colSpan="2">
									{this.renderButton(item)}
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan="2">Arrived at HHTT Site </Table.Cell>
								<Table.Cell colSpan="2">
									{item.deliveryStatus === "DELIVERED_AT_HHTT_SITE" ?
										<Icon color='green'
										      name='checkmark'
										      size='large'
										      Button
										      onClick={this.show("WORKSHOP_TO_HHTT_SITE",
											      item.id,
											      "Are you sure you want to cancel check in for this item?"
										      )}/>
										:
										<Button onClick={this.show("DELIVERED_AT_HHTT_SITE",
											item.id,
											"Are you sure you want to check in this item?")}>
											Check-in
										</Button>
									}
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				})}

				<Modal size={"mini"} open={open} onClose={this.close}>
					<Modal.Content>
						<p>{this.state.message}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.close}>No</Button>
						<Button positive icon='checkmark' labelPosition='right' content='Yes'
						        onClick={() => {
							        this.handleCheckIn(this.state.status, this.state.itemId)
						        }}/>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}

export default ItemElementArray;
