import React, {Component} from 'react';
import Fade from 'react-reveal';
import NavigationElement from "../shared/NavigationElement";
import ItemElementArray from "../shared/ItemElementArray";
import Cookies from "react-cookie/es6/Cookies";
import Instruction from "../shared/Instruction";

class ItemElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            elementItems: [],
            tankId: '',
            steps: [
                {
                    key: 'tankpit',
                    title: 'Tank Pit ' + props.tankPitId,
                    href: "/steel/tankpits/all"
                },
                {
                    key: 'tank',
                    title: 'Tank ' + props.crateNumber,
                    href: "/steel/tankpits/" + props.tankPitId + "/tanks/all"
                },
                {
                    key: props.itemName,
                    active: true,
                    title: props.itemName,
                    href: "/steel/tankpits/" + props.tankPitId + "/tanks/" + props.crateNumber + "/items/all"
                }
            ],
        };
    }

    componentDidMount() {
        let self = this;
        const cookies = new Cookies();
        // fetch(process.env.REACT_APP_API_BASE_URL + "/hes-tracker/tankpit/" + this.props.tankPitId + "/tanks", {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/container/${this.props.tankPitId}/crates`, {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': cookies.get('authToken')
            }
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                // window.location.replace("/login");
                throw new Error(response.statusText);
            }
            return response.json()
        }).then(function (data) {
            let tankarray = data.crates;
	        let tankId = tankarray.find(x => x.crateNumber === self.props.crateNumber).id;

            // fetch(process.env.REACT_APP_API_BASE_URL + "/hes-tracker/tank/" + tankId + "/items", {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/crate/${tankId}/items`, {

                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': cookies.get('authToken')
                }
            }).then(function (response) {
                if (!response.ok) {
                    console.log("error", response);
                    // window.location.replace("/login");
                    throw new Error(response.statusText);
                }
                return response.json()
            }).then(function (tankItemsObj) {
                let tankItemsArray = tankItemsObj.crateItems;
                let matchedItem = tankItemsArray.find(x => x.itemName === self.props.itemName);
                let elementItems = matchedItem.items;
                self.setState({elementItems: elementItems, tankId: tankId})
            });
        });
    }


    render() {
        return (
            <div>
                <Fade>
                    <NavigationElement items={this.state.steps}/>
                    <Instruction text="NOTICE: Check specifications and the correct milestone before check-in"/>
                    <ItemElementArray items={this.state.elementItems} tankId={this.state.tankId}
                                      itemName={this.props.itemName}/>
                </Fade>
            </div>
        );
    }
}

export default ItemElement;
