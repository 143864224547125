import React, {Component} from 'react';
import Step from "semantic-ui-react/dist/commonjs/elements/Step/Step";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import {Cookies} from "react-cookie";

const centerStyle = {
	maxWidth: "800px",
	margin: "auto",
	paddingBottom: "2em",
	paddingTop: "1em",
	backgroundColor: "white"
};


class Home extends Component {

	static isAuthenticated() {
		const cookies = new Cookies();
		console.log(!!cookies.get("authToken"));
		return !!cookies.get("authToken");
	}


	render() {
		return (
			<div>

				<div style={centerStyle}>
					<h2 style={{fontSize: "24px", marginLeft: "0.5em"}}>How does it work?</h2>
					<Step.Group fluid vertical>
						{!Home.isAuthenticated() ? <Step href="login" active>
							<Icon name='hand point up outline'/>
							<Step.Content>
								<Step.Title>Sign in </Step.Title>
								<Step.Description>Use the credentials supplied by your administrator</Step.Description>
							</Step.Content>
						</Step> : null}

						<Step>
							<Icon name='sitemap'/>
							<Step.Content>
								<Step.Title>Tank Pit</Step.Title>
								<Step.Description>Select a tank pit</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='sitemap'/>
							<Step.Content>
								<Step.Title>Tank</Step.Title>
								<Step.Description>Select a tank</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='sitemap'/>
							<Step.Content>
								<Step.Title>Choose item type</Step.Title>
								<Step.Description>Select the item type</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='sitemap'/>
							<Step.Content>
								<Step.Title>Select item</Step.Title>
								<Step.Description>Select an item</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='checkmark' color='green'/>
							<Step.Content>
								<Step.Title>Confirm status</Step.Title>
								<Step.Description>Set the status of the correct component</Step.Description>
							</Step.Content>
						</Step>

					</Step.Group>
				</div>
			</div>
		);
	}
}

export default Home;