import React, {Component} from 'react';
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";

class Approver extends Component {
    constructor(props) {
        super(props);
        this.state = {approved: false, inputText: '', mode: 'view'};
        console.log(props.id, props.status);
        fetch(process.env.REACT_APP_API_BASE_URL + '/hes-tracker/item/redeem/' + props.id, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({deliveryStatus: props.status})
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
            return response
        }).then(() => this.setState({approved: true})).catch(error => console.log(error));
    };

    render() {
        return (
            <div>
            {this.state.approved === true ?
                <Message positive>
                    <p>
                        Your response has been submitted
                    </p>
                </Message> : <Message negative>
                    <p>
                        Response failed
                    </p>
                </Message>}
            </div>
        );
    }
}

export default Approver;