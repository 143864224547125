import React, {Component} from 'react';
import Fade from "react-reveal/Fade";


class Dashboard extends Component {
    render() {
        return (
            <div style={{height: "800px"}}>
                <Fade>
                    <iframe title="dash" width="100%" height="800px" style={{overflow:"hidden",height:"100%",width:"100%"}}
                            src="https://app.powerbi.com/view?r=eyJrIjoiNTY1NjRmMGMtNGM5OC00MmY4LWE0ZmMtOTlhZWZlODM0ZGU1IiwidCI6IjEyMmIxNDVhLWExYTEtNDBhZC05MTUwLTJkY2I5ODk3NmJmMiIsImMiOjl9"
                            frameBorder="0" allowFullScreen="true"/>
                </Fade>
            </div>
        );
    }
}

export default Dashboard;