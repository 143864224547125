import React, { Component } from 'react'
import Fade from 'react-reveal'
import NavigationElement from '../shared/NavigationElement'
import FullWidthButtonArray from '../shared/FullWidthButtonArray'
import Instruction from '../shared/Instruction'
import Cookies from 'react-cookie/es6/Cookies'

class Items extends Component {
	constructor (props) {
		super(props)

		this.state = {
			tankItems: [],
			steps: [
				{
					key: 'tankpit',
					title: 'Tank Pit ' + props.tankPitId,
					href: '/steel/tankpits/all',
				},
				{
					key: 'tank',
					title: 'Tank ' + props.crateNumber,
					href: '/steel/tankpits/' + props.tankPitId + '/tanks/all',
				},
				{
					key: 'item',
					active: true,
					title: 'Item',
				},
			],
		}
	}

	componentDidMount () {
		let self = this
		const cookies = new Cookies()
		fetch(`${process.env.REACT_APP_API_BASE_URL}/container/${this.props.tankPitId}/crates`, {
			headers: {
				'Content-Type': 'application/json',
				'X-Auth-Token': cookies.get('authToken'),
			},
		}).then(response => response.json()).then(function (data) {
			let tankArray = data.crates
			let tankId = tankArray.find(x => x.crateNumber === self.props.crateNumber).id

			// fetch(process.env.REACT_APP_API_BASE_URL + '/hes-tracker/tank/' + tankId + '/items', {
				fetch(`${process.env.REACT_APP_API_BASE_URL}/crate/${tankId}/items`, {
				headers: {
					'Content-Type': 'application/json',
					'X-Auth-Token': cookies.get('authToken'),
				},
			}).then(response => response.json()).then(function (tankItemsObj) {
				let tankItemsArray = tankItemsObj.crateItems

				let i
				for (i = 0; i < tankItemsArray.length; i++) {
					tankItemsArray[i].name = tankItemsArray[i]['itemName']
					tankItemsArray[i].url = '/steel/tankpits/' + self.props.tankPitId + '/tanks/' +
						self.props.crateNumber + '/items/' + tankItemsArray[i]['itemName'] + '/all'

					let j
					let k = 0
					let color = '#1FAA00'
					for (j = 0; j < tankItemsArray[i].items.length; j++) {
						if (tankItemsArray[i].items[j].deliveryStatus !== 'DELIVERED_AT_HHTT_SITE') {
							color = '#4D4D4F'
							k++
						}
					}

					tankItemsArray[i].color = color
					tankItemsArray[i].counter = '(' + (j - k) + '/' + (j) + ')'

				}
				self.setState({ tankItems: tankItemsArray })
			})
		})
	}

	render () {
		return (
			<div>
				<Fade>
					<NavigationElement items={this.state.steps}/>
					<Instruction text="Select an item"
								 previousLink={'steel/tankpits/' + this.props.tankPitId + '/tanks/all'}/>
					<FullWidthButtonArray prefix="" items={this.state.tankItems}/>
				</Fade>
			</div>
		)
	}
}

export default Items
