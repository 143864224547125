import React, {Component} from 'react';
import Fade from 'react-reveal';
import ButtonArray from "../shared/ButtonArray";

const buttonArray = [
	{name: "Steel",
	url: "/steel/tankpits/all"},
    {name: "Piping",
        url: "/piping"}
];


class Delivery extends Component {
	render() {
		return (
			<div>
				<Fade>
					<ButtonArray prefix="" items={buttonArray}/>
				</Fade>
			</div>
		);
	}
}

export default Delivery;