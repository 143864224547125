import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import {translate} from 'react-multi-lang';

import './App.css';
import Language from './Language'
import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./components/collections/Home";
import NoRef from "./components/404";
import SignIn from "./components/collections/SignIn";
import TankPits from "./components/collections/TankPits";
import Dashboard from "./components/collections/Dashboard";
import Tanks from "./components/collections/Tanks";
import Delivery from "./components/collections/Delivery";
import ItemElement from "./components/collections/ItemElement";
import Items from "./components/collections/Items";
import Redirect from "react-router/es/Redirect";
import {Cookies, withCookies} from 'react-cookie';
import {instanceOf} from 'prop-types';
import Filler from "./components/collections/Filler";
import SneakPreview from "./components/collections/SneakPreview";
import Approver from "./components/status-approval/Approver";

const NoMatch = ({location}) => (
	<div>
		<NoRef location={location}/>
	</div>
);

function PrivateRoute({component: Component, ...rest}) {
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated() ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: {from: props.location}
						}}
					/>
				)
			}
		/>
	);
}

function isAuthenticated() {
	// const cookies = new Cookies();
	// console.log(!!cookies.get("authToken"));
	// return !!cookies.get("authToken");
	return true;
}

function TankChild({match}) {
	return (
		<div>
			<Tanks tankPitId={match.params.tankPitId}/>
		</div>
	);
}

function ItemChild({match}) {
	return (
		<div>
			<Items tankPitId={match.params.tankPitId} crateNumber={match.params.tankNumber}/>
		</div>
	);
}

function ItemElementChild({match}) {
	return (
		<div>
			<ItemElement tankPitId={match.params.tankPitId} crateNumber={match.params.tankNumber}
			             itemName={match.params.itemName}/>
		</div>
	);
}

function ApproverChild({match}) {
	return (
		<div>
			<Approver id={match.params.id} status={match.params.status}/>
		</div>
	);
}

class App extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		Language.initialize();

		const {cookies} = props;
		this.state = {
			authenticationToken: cookies.get('X-Authentication-Token') || "",
			loggedIn: window.location.pathname !=="/login"
		};
	}

	login = (value) => {
		this.setState({value})
	}

	render() {
		return (
			<div className="App">
				<Header logOut={this.login} loggedIn={this.state.loggedIn}/>
				<Switch>
					<Route path="/" exact component={Home}/>
					<Route path="/login" exact render={(props) => <SignIn login={this.login} {...props}/>}/>
					<Route path="/delivery" component={Delivery}/>
					<Route path="/status-confirmation/:id/:status" component={ApproverChild}/>
					<PrivateRoute path="/steel/tankpits/all" component={TankPits}/>
					<PrivateRoute path="/steel/tankpits/:tankPitId/tanks/all" component={TankChild}/>
					<PrivateRoute path="/steel/tankpits/:tankPitId/tanks/:tankNumber/items/all" component={ItemChild}/>
					<PrivateRoute path="/steel/tankpits/:tankPitId/tanks/:tankNumber/items/:itemName/all"
					       component={ItemElementChild}/>
					<PrivateRoute path="/dashboard" component={Dashboard}/>
					<PrivateRoute path="/filler" component={Filler}/>
					<PrivateRoute path="/piping" component={Filler}/>
					<PrivateRoute path="/free-issue" component={Filler}/>
					<PrivateRoute path="/mechanical" component={SneakPreview}/>
					<Route component={NoMatch}/>
				</Switch>
				<Fade bottom>
					<Footer/>
				</Fade>
			</div>
		);
	}
}

export default withCookies(translate(App));
