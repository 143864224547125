import React, {Component} from "react";
import {translate} from "react-multi-lang"
import {Form} from 'semantic-ui-react'
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
// import Cookies from "react-cookie/es6/Cookies";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
// import jwt_decode from "jwt-decode";

const loginFormStyle = {
	maxWidth: "400px",
	margin: "auto",
	padding: "0 1em 2em 1em",
	fontSize: "16px"
};

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formError: ""
		}

	}
	state = {password: '', email: '', submittedPassword: '', submittedEmail: ''};

	handleChange = (e, {name, value}) => this.setState({[name]: value});

	// handleSubmit = () => {
	// 	let self = this;
	// 	const {email, password} = this.state;
	// 	this.setState({submittedPassword: password, submittedEmail: email});
	// 	fetch(process.env.REACT_APP_API_BASE_URL + '/auth/email/login', {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify({
	// 			email: email,
	// 			password: password
	// 		})
	// 	}).then(function (response) {
	// 		if (!response.ok) {
	// 			response.json().then(function (object) {
	// 				console.log(object);
	// 				console.log(object.propertyErrors);
	// 				self.setState({formError: object.propertyErrors})
	// 			});
	// 			throw new Error(response.statusText);
	// 		}
	// 		return response.json()
	// 	}).then(function (data) {
	// 		console.log("hit");
	// 		const cookies = new Cookies();
	// 		cookies.set('authToken', data.token, {path: '/'});
	//
	// 		let decoded = jwt_decode(data.token);
	// 		if (decoded.authorities.map(a => a.authority).includes('ROLE_MANAGEMENT')) {
	// 			window.location.replace("/dashboard")
	// 		} else {
	// 			window.location.replace("/steel/tankpits/all")
	// 		}
	// 	}).catch(error => console.log(error));
	// };

	fakeSubmit = () => {
		this.props.login(true);
		window.location.replace("/steel/tankpits/all")

	}

	render() {
		const {password, email} = this.state;

		return (
			<div className="services-wrapper">
				<div className="">
					<Form style={loginFormStyle} onSubmit={this.fakeSubmit} error={this.state.formError}>
						<Form.Input name="email" value={email} label='Email' placeholder='test@hes.com'
						            onChange={this.handleChange}/>
						<Form.Input name="password" value={password} label='Enter Password' type='password' placeholder='password'
						            onChange={this.handleChange}/>
						<Button style={{marginTop: "0.7em", fontSize: "18px"}} color="orange" type='submit'>Sign In</Button>
						<Message error>
							There were some problems authenticating with the following errors:
							<Message.List items={this.state.formError}/>
						</Message>
					</Form>
				</div>
			</div>
		);
	}
}

export default translate(Login);
